import axios from 'axios';
import Cookie from 'js-cookie';
import qs from 'qs';

export default {
  Login: function(payload, cb, errcb) {
    axios.post(`/api/auth/token/`,
      {
        username: payload.username,
        password: payload.password
      })
      .then(result => {
        localStorage.setItem('jwt', result.data.token);
        Cookie.set('edgedx-auth-token', result.data.token);
        try{
          const jwt = JSON.parse(""+(result.data.token.split('.').map((item) => Buffer.from(item, 'base64').toString('utf-8'))[1]));
          localStorage.setItem('jwt_exp', jwt.exp);
        }catch(err){
          console.log(err);
        }
        cb(result);
      })
      .catch(e => errcb(e))
  },
  Logout: function(cb, errcb) {
    axios.post(`/api/auth/token/logout/`)
      .then(result => cb(result))
      .catch(e => errcb(e))
      .finally(()=>{
        localStorage.removeItem('jwt');
        localStorage.removeItem('jwt_exp');
      })
  },
  GetDevices: function(cb, errcb, params) {
    let query_string = ''
    if (params) {
      query_string = '?' + qs.stringify(params)
    }
    axios.get(`/api/devices/${query_string}`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddDevices: function(cb, errcb, payload) {
    axios.post(`/api/upload/devices/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddBranches: function(cb, errcb, payload) {
    axios.post(`/api/upload/branches/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddUsers: function(cb, errcb, payload){
    axios.post(`/api/upload/users/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },


  GetTimelapses: function(cb, errcb, params) {
    let query_string = ''
    if (params) {
      query_string = '?' + qs.stringify(params)
    }
    axios.get(`/api/timelapse/generations/${query_string}`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },
  AddTimelapse: function(cb, errcb, payload) {
    axios.post(`/api/timelapse/generations/`, payload)
      .then(result => cb(result))
      .catch(e => errcb(e))
  },


  GetHlsUrl: function(cb, errcb, params) {
    axios.get(`/api/devices/${params.id}/stream/`)
      .then(result => cb(result))
      .catch(e => errcb(e))
  }
}
