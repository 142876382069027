import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  capability: null,
  device_info: null
}

const actions = {
  refreshCapability({commit, dispatch}) {
    axios.get(`/api/capability/`)
      .then(response => {
        commit('set', ['capability', response.data]);
      })
  }
};

const getters = {
  myAuthorizationLevel(state) {
    if (!state.capability) {
      return {
        "id": 1000,
        "name": "Unknown",
        "level": 10000
      };
    }
    const all_auths = state.capability.auth_levels;
    const my_auth = all_auths.find(auth => auth.level === state.capability.user_profile.auth_level);
    return my_auth;
  },
  myOrganization(state) {
    if (!state.capability) {
      return null;
    }
    return state.capability.organization;
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
