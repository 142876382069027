export default {
  socket: null,
  socket_status: 'close',
  conn_id: null,

  initialize() {
    setInterval(() => {
      // console.log('Check WebSocket connection');
      this.checkConnection();
    }, 5000);
  },

  checkConnection() {
    if (this.socket_status === 'connected') {
      // console.log('Already connected');
      return;
    }
    if (localStorage.getItem('jwt') === null) {
      // console.log('Not logged in');
      return;
    }
    // console.log('try to connect');
    this.requestConnection();
  },

  requestConnection() {
    const token = localStorage.getItem('jwt');
    const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
    const ws_path = `${ws_scheme}://${window.location.host}/ws/common/?token=${token}`;
    this.socket = new WebSocket(ws_path);
    this.socket_status = 'connecting';

    // 연결 성공
    this.socket.onopen = () => {
      console.log("Server connection is open now.");
      this.socket_status = 'connected';
    };
    // 연결 오류
    this.socket.onerror = (event) => {
      console.error("Server connection error observed:", event);
      this.socket_status = 'close';
    };
    // 연결 종료
    this.socket.onclose = (event) => {
      console.log("Server connection is closed now.", event);
      this.socket_status = 'close';
    };
    // 메시지 수신
    this.socket.onmessage = this.recv;
  },

  recv(event) {
    const data = JSON.parse(event.data);
    const command = data.server_cmd;
    if (command === 'connected') {
      this.conn_id = data.conn_id;
    } else if (command === 'alarm_notification') {
      // 알람 수신
      let myEvent = new CustomEvent("alarm_notification", {
        detail: { message: data.message }
      });
      document.dispatchEvent(myEvent);
    }
  },
  send(message) {
    if (this.socket_status !== 'connected') {
      console.error("WebSocket is not connected.");
      return;
    }
    this.socket.send(JSON.stringify(message));
  },

  finalize() {
    this.socket.close();
  }
}
