import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n'
import locale_en from 'element-ui/lib/locale/lang/en'
import locale_ko from 'element-ui/lib/locale/lang/ko'
import messages from './assets/i18n-messages.json'
import 'element-ui/lib/theme-chalk/index.css'
import { iconsSet as icons } from './assets/icons/icons.js'
import * as svgicon from 'vue-svgicon'
import store from './store'
import api from './api'
import ws from './ws'
import AxiosConfig from './config'
import resource from './static_resources.json'
// import ws from './ws'
import axios from 'axios'
import Cookie from 'js-cookie';
import './icons';
import utililty from './utility.js';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
AxiosConfig()

Vue.prototype.$resource = resource;
Vue.prototype.$api = api;
Vue.prototype.$utility = utililty;
Vue.prototype.$ws = ws;

Vue.use(ElementUI, { locale : locale_en });
Vue.use(svgicon, {
  tagName: 'svgicon',
  defaultWidth: '50px',
  defaultHeight: '50px'
});
Vue.use(VueLayers);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-tooltip', LTooltip);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// axios.get(`/api/capability/`)
//   .then(response => {
//     store.commit('set', ['capability', response.data]);
//     lang = store.state.capability.user_profile.locale;
//   })
//   .catch(error => {
//     console.error(error);
//     if (error.response.status === 403) {
//       router.push('/pages/login');
//     }
//   })
//   .finally(() => {
//     const i18n = new VueI18n({
//       locale: lang,
//       fallbackLocale: 'en',
//       messages: messages,
//     })
//     Vue.use(ElementUI, { locale : locales[lang] });
//     new Vue({
//       el: '#app',
//       router,
//       store,
//       icons,
//       i18n,
//       template: '<App/>',
//       components: {
//         App
//       }
//     })
//   })

const NO_LOGIN_PAGES = [
  '/pages/login',
  '/pages/event-expired',
  '/pages/500',
  '/pages/404',
  '/service/delivery/wearing/'
];
router.beforeEach((to, from, next) => {
  // 로그인 불요한 페이지는 즉시 이동
  if (NO_LOGIN_PAGES.indexOf(to.path) >= 0) {
    return next();
  }

  //JWT 토큰 인증시간 체크
  const jwt_exp = localStorage.getItem('jwt_exp');
  if (jwt_exp) {
    const remain = jwt_exp - Date.now() / 1000;
    //console.log(remain);
    if (remain < 43200) { /* 12(h) * 60(m) * 60(s) */
      const token = localStorage.getItem('jwt');
      axios.post(`/api/auth/token/refresh/`,
        {
          token: token
        })
        .then(result => {
          console.log("refresh token");
          localStorage.setItem('jwt', result.data.token);
          Cookie.set('edgedx-auth-token', result.data.token);
          try {
            const jwt = JSON.parse("" + (result.data.token.split('.').map((item) => Buffer.from(item, 'base64').toString('utf-8'))[1]));
            localStorage.setItem('jwt_exp', jwt.exp);
          } catch (err) {
            console.log(err);
          }
        })
        .catch(() => {
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_exp');
        })
    }
  }

  // 로그인 필요한 페이지는 capability 체크
  if (store.state.capability) {
    // 채널 파트너 애플리케이션 로그인 안됨
    const auth_levels = store.state.capability.auth_levels;
    const my_level = store.state.capability.user_profile.auth_level;
    if (auth_levels.findIndex(el => el.level === my_level) < 0) {
      alert('This is not an application user');
      return next(`/pages/login`);
    }
    return next();
  }
  axios.get(`/api/capability/`)
    .then(response => {
      // 채널 파트너 애플리케이션 로그인 안됨
      const auth_levels = response.data.auth_levels;
      const my_level = response.data.user_profile.auth_level;
      if (auth_levels.findIndex(el => el.level === my_level) < 0) {
        alert('This is not an application user');
        return next(`/pages/login`);
      }
      store.commit('set', ['capability', response.data]);
      localStorage.lang = response.data.user_profile.locale;
      next();
    })
    .catch(error => {
      console.error(error);
      console.log(to);
      next(`/pages/login?redirect=${window.btoa(to.fullPath)}`);
    })
});

ws.initialize();

Vue.use(VueI18n);
const locales = {
  en: locale_en,
  ko: locale_ko
}
let lang = localStorage.lang || 'en';
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: messages,
})
Vue.use(ElementUI, { locale : locales[lang] });
new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
