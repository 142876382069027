import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Home
const Dashboard = () => import('@/views/dashboard/Dashboard')

// Alarm
const AlarmLive = () => import('@/views/alarm/alarms/LiveAlarm')
const AlarmSearch = () => import('@/views/alarm/alarms/AlarmSearch')
const AlarmReporting = () => import('@/views/alarm/report')
const IgnoringInterval = () => import('@/views/ignoring-interval/IgnoringInterval')
const AlarmView = () => import('@/views/alarm/alarms/AlarmView')

// Statistics
// const StatisticsDaily = () => import('@/views/statistics/Daily')
// const StatisticsWeekly = () => import('@/views/statistics/Weekly')
// const StatisticsMonthly = () => import('@/views/statistics/Monthly')

// Organization Setup
const SetupOrganization = () => import('@/views/setup/organization/Organization')
const SetupBranches = () => import('@/views/setup/branches/Branches')
const SetupUsers = () => import('@/views/setup/users/Users')
const SetupDevices = () => import('@/views/setup/devices/Devices')
const SetupDeviceRegistration = () => import('@/views/setup/devices/Registration')
const SetupDeviceIgnoreInterval = () => import('@/views/setup/devices/IgnoreInterval')
const DevicesView = () => import('@/views/setup/devices/DevicesView')

// Common Service
// const InstallDashboard = () => import('@/views/install/dashboard/Dashboard')
const Devices = () => import('@/views/install/devices/Devices')
const Branches = () => import('@/views/install/branches/Branches')
const Organizations = () => import('@/views/install/organizations/Organizations')
const Users = () => import('@/views/install/users/Users')
// const Profile = () => import('@/views/install/users/Profile')

const AppOccupancy = () => import('@/views/apps/occupancy')
const AppOccupancySettings = () => import('@/views/apps/occupancy/settings/Settings.vue')
const AppOccupancyDownload = () => import('@/views/apps/occupancy/Download.vue')

const AppPeopleCounting = () => import('@/views/apps/people_counting')
const AppPeopleCountingSettings = () => import('@/views/apps/people_counting/settings/Settings.vue')
const AppPeopleCountingDownload = () => import('@/views/apps/people_counting/Download.vue')

const AppVisitAdvanced = () => import('@/views/apps/visit_advanced')
const AppVisitAdvancedSettings = () => import('@/views/apps/visit_advanced/settings/Settings.vue')
const AppVisitAdvancedDownload = () => import('@/views/apps/visit_advanced/Download.vue')

const AppLprUS = () => import('@/views/apps/lpr_us')
const AppLprUSDevice = () => import('@/views/apps/lpr_us/device_sync')
const AppLprEU = () => import('@/views/apps/lpr_eu')
const AppLprEUDevice = () => import('@/views/apps/lpr_eu/device_sync')
const AppLprJP = () => import('@/views/apps/lpr_jp')
const AppLprJPDevice = () => import('@/views/apps/lpr_jp/device_sync')
const AppLprKR = () => import('@/views/apps/lpr_kr')
const AppLprKRDevice = () => import('@/views/apps/lpr_kr/device_sync')

// // Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Settings = () => import('@/views/pages/Settings')
const Profile = () => import('@/views/pages/Profile')
const Test = () => import('@/views/pages/Test')

// Log
const EventLog = () => import('@/views/log/EventLog')
const InitPage = () => import('@/views/pages/InitPage')

const EventViewExpired = () => import('@/views/pages/EventViewExpired')

const KisaVerification = () => import('@/views/kisa-verification')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});
export default router;

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard
        }
      ]
    },
    {
      path: '/alarm',
      redirect: '/alarm/live',
      component: TheContainer,
      children: [
        {
          path: 'live',
          name: 'AlarmLive',
          component: AlarmLive
        },
        {
          path: 'search',
          name: 'AlarmSearch',
          component: AlarmSearch
        },
      ]
    },
    {
      path: '/ignoring-interval',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Alarm',
          component: IgnoringInterval
        }
      ]
    },

    {
      path: '/apps/occupancy',
      redirect: '/apps/occupancy/analytics',
      component: TheContainer,
      children: [
        {
          path: 'analytics',
          name: 'App Occupancy Analytics',
          component: AppOccupancy
        },
        {
          path: 'settings',
          name: 'App Occupancy Settings',
          component: AppOccupancySettings
        }
      ]
    },
    {
      path: '/apps/people_counting',
      redirect: '/apps/people_counting/analytics',
      component: TheContainer,
      children: [
        {
          path: 'analytics',
          name: 'App PeopleCounting Analytics',
          component: AppPeopleCounting
        },
        {
          path: 'settings',
          name: 'App PeopleCounting Settings',
          component: AppPeopleCountingSettings
        }
      ]
    },
    {
      path: '/apps/visit_advanced',
      redirect: '/apps/visit_advanced/analytics',
      component: TheContainer,
      children: [
        {
          path: 'analytics',
          name: 'AppVisitAdvancedAnalytics',
          component: AppVisitAdvanced
        },
        {
          path: 'settings',
          name: 'AppVisitAdvancedSettings',
          component: AppVisitAdvancedSettings
        }
      ]
    },
    {
      path: '/apps/lpr_us',
      redirect: '/apps/lpr_us/lp_management',
      component: TheContainer,
      children: [
        {
          path: 'lp_management',
          name: 'AppLprUSLPManagement',
          component: AppLprUS
        },
        {
          path: 'devices',
          name: 'AppLprUSDevice',
          component: AppLprUSDevice
        }
      ]
    },
    {
      path: '/apps/lpr_eu',
      redirect: '/apps/lpr_eu/lp_management',
      component: TheContainer,
      children: [
        {
          path: 'lp_management',
          name: 'AppLprEULPManagement',
          component: AppLprEU
        },
        {
          path: 'devices',
          name: 'AppLprEUDevice',
          component: AppLprEUDevice
        }
      ]
    },
    {
      path: '/apps/lpr_jp',
      redirect: '/apps/lpr_jp/lp_management',
      component: TheContainer,
      children: [
        {
          path: 'lp_management',
          name: 'AppLprJPLPManagement',
          component: AppLprJP
        },
        {
          path: 'devices',
          name: 'AppLprJPDevice',
          component: AppLprJPDevice
        }
      ]
    },
    {
      path: '/apps/lpr_kr',
      redirect: '/apps/lpr_kr/lp_management',
      component: TheContainer,
      children: [
        {
          path: 'lp_management',
          name: 'AppLprKRLPManagement',
          component: AppLprKR
        },
        {
          path: 'devices',
          name: 'AppLprKRDevice',
          component: AppLprKRDevice
        }
      ]
    },

    {
      path: '/report',
      redirect: '/pages/404',
      name: 'Report Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'alarm',
          name: 'ReportAlarm',
          component: AlarmReporting
        },
        {
          path: 'apps/occupancy',
          name: 'ReportAppOccupancy',
          component: AppOccupancyDownload
        },
        {
          path: 'apps/people_counting',
          name: 'ReportAppPeopleCounting',
          component: AppPeopleCountingDownload
        },
        {
          path: 'apps/visit_advanced',
          name: 'ReportAppVisitAdvanced',
          component: AppVisitAdvancedDownload
        }
      ]
    },
    {
      path: '/service',
      redirect: '/pages/404',
      name: 'Service Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'alarm-view',
          name: 'AlarmView',
          component: AlarmView
        }
      ]
    },
    {
      path: '/setup',
      redirect: '/setup/organization',
      name: 'Setting',
      component: TheContainer,
      children: [
        {
          path: 'organization',
          name: 'Organizations',
          component: SetupOrganization
        },
        {
          path: 'branches',
          name: 'Branches',
          component: SetupBranches
        },
        {
          path: 'users',
          name: 'Users',
          component: SetupUsers
        },
        {
          path: 'devices',
          name: 'Devices',
          component: SetupDevices
        },
        {
          path: 'device-registration',
          name: 'DeviceRegistration',
          component: SetupDeviceRegistration
        },
        {
          path: 'device-ignore-interval',
          name: 'DeviceIgnoreInterval',
          component: SetupDeviceIgnoreInterval
        }
      ]
    },
    {
      path: '/install',
      redirect: '/install/organizations',
      name: 'Install',
      component: TheContainer,
      children: [
        // {
        //   path: 'dashboard',
        //   name: '설치 대시보드',
        //   component: InstallDashboard
        // },
        {
          path: 'organizations',
          name: 'Organization',
          component: Organizations
        },
        {
          path: 'branches',
          name: 'Branch',
          component: Branches
        },
        {
          path: 'devices',
          name: 'Device',
          component: Devices
        },
        {
          path: 'users',
          name: 'User',
          component: Users
        }
      ]
    },
    {
      path: '/log',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Log',
          component: EventLog
        }
      ]
    },
    {
      path: '/views',
      redirect: '/views/devices',
      name: 'Views',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'devices',
          name: 'DevicesView',
          component: DevicesView
        }
      ]
    },
    {
      path: '/kisa-verification',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'KisaVerification',
          component: KisaVerification
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'init-page',
          name: 'Initial page',
          component: InitPage
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'settings',
          name: 'Settings',
          component: Settings
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'event-expired',
          name: 'EventViewExpired',
          component: EventViewExpired
        },
        {
          path: 'test',
          name: 'Test',
          component: Test
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: "/pages/404"
    }
  ]
}
